<template>
  <div id="fpt">
    <b-input
      v-for="(char, i) in passToken"
      :ref="() => 'pass-token-' + i"
      v-model="passToken[i]"
      size="1"
      maxlength="1"
      class="sequential-code"
      @keyup="nextEle"
      required
      :autofocus="!i"
      :formatter="formatter"
      :key="i"
      debounce="350"
    ></b-input>
  </div>
</template>

<script>
export default {
  props: {
    chars: {
      type: Number,
      default: 4,
    },
    formatter: {
      type: Function,
      default: null,
    },
  },
  name: "FormPassToken",
  data: () => ({
    passToken: [],
  }),
  computed: {
    finalPassToken() {
      return this.passToken.join("");
    },
  },
  watch: {
    passToken: {
      deep: true,
      handler: "emitInput",
    },
  },
  created() {
    for (let i = 0; i < this.chars; i++) {
      this.passToken.push("");
    }
  },
  methods: {
    emitInput() {
      if (this.finalPassToken.length === this.chars) {
        this.$emit("input", this.finalPassToken);
      }
    },
    nextEle(e) {
      if (e.target.value) {
        if (e.target.nextElementSibling) {
          e.target.nextElementSibling.focus();
        }
      } else if (e.target.previousElementSibling) {
        e.target.previousElementSibling.focus();
      }
    },
  },
};
</script>

<style scoped>
input.sequential-code {
  height: 64px;
  width: 82px;
  font-size: 56px;
  text-align: center;
  display: inline-block;
  margin: 7px;
}
</style>
