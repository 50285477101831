<template>
  <div class="c-app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card no-body class="p-4">
            <b-card-body>
              <h1>
                <span class="brand"></span>
                Password Reset
              </h1>

              <!-- SUBMIT EMAIL -->
              <b-container v-if="!emailSubmitted">
                <p>
                  Forget your password? No worries - it happens to the best of us! Enter
                  your email address below and we'll send you a message containing a code
                  which you can use to varify your account. Then you'll be allowed to
                  reset your password and login.
                </p>
                <b-row>
                  <b-col cols="12">
                    <b-form @submit="onSubmitEmail">
                      <b-form-group>
                        <b-input-group>
                          <b-input
                            type="email"
                            v-model="email"
                            placeholder="you@youremailprovider.com"
                            required
                          ></b-input>
                          <b-input-group-append>
                            <b-button type="submit" variant="info"
                              ><i class="fa fa-check"></i> Continue</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-form>
                  </b-col>
                </b-row>
              </b-container>

              <!-- SUBMIT VALIDATION CODE -->
              <b-container v-else-if="!passTokenSubmitted">
                <p>
                  Please check your email for a varification code (delivery may take a
                  moment) and enter it below.
                </p>
                <b-row>
                  <b-col cols="12" class="text-center">
                    <FormPassToken
                      :formatter="(value) => value.replaceAll('/[^0-9]/g', '')"
                      lazy-formatter
                      v-model="passToken"
                      @input="onSubmitPassToken()"
                    />
                  </b-col>
                </b-row>
              </b-container>

              <!-- SUBMIT PASSWORD -->
              <b-container v-else>
                <p>Plese use the fields below below to reset your password.</p>
                <b-row>
                  <b-col cols="12">
                    <b-form @submit.prevent="onSubmitPassword">
                      <b-form-group
                        label="Password"
                        label-for="input-password"
                        label-sr-only
                      >
                        <b-form-input
                          class="form-control input-lg"
                          v-model="plainPassword.first"
                          placeholder="Password"
                          maxlength="4096"
                          id="input-password"
                          value=""
                          type="password"
                        />
                      </b-form-group>
                      <b-form-group
                        label="Confirm Password"
                        label-for="input-password2"
                        label-sr-only
                      >
                        <b-form-input
                          class="form-control input-lg"
                          :state="passwordsMatch"
                          v-model="plainPassword.second"
                          placeholder="Confirm Password"
                          maxlength="4096"
                          id="input-password2"
                          value=""
                          type="password"
                        />
                      </b-form-group>
                      <div>
                        <b-button type="submit" variant="success"
                          ><i class="fa fa-check"></i> Update My Password</b-button
                        >
                      </div>
                    </b-form>
                  </b-col>
                </b-row>
              </b-container>
              <p class="float-right">
                <b-button variant="link" class="p-0 pr-3" :to="{ name: 'Login' }"
                  >Back to Login</b-button
                >
              </p>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FormPassToken from "../components/FormPassToken.vue";

export default {
  name: "PasswordReset",
  components: { FormPassToken },
  head() {
    return {
      title: "Password Reset",
    };
  },
  data() {
    return {
      email: null,
      emailSubmitted: false,
      passToken: null,
      passTokenSubmitted: false,
      plainPassword: {
        first: "",
        second: "",
      },
      userData: {},
    };
  },
  computed: {
    passwordsMatch() {
      if (this.plainPassword.first !== this.plainPassword.second) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions(["resetPassword"]),
    tokenFormatter(value) {
      return value.replaceAll("/[^0-9]/g", "");
    },
    onSubmitEmailValidate() {
      if (this.email === "") {
        this.$toast.error("You must submit a registered email address");
        return false;
      }
      return true;
    },
    async onSubmitEmail(e) {
      e.preventDefault();
      if (!this.onSubmitEmailValidate) return;
      // @TODO ini loading
      // request token
      try {
        await this.resetPassword({
          email: this.email,
        });
      } catch (e) {
        console.error(e);
        this.toastError("There was an error finding that email");
        // @TODO complete loading
        return;
      }

      // @TODO complete loading
      this.emailSubmitted = true;
    },
    onSubmitPassTokenValidate(passToken) {
      if (isNaN(passToken) || passToken.length > 4) {
        this.toastError("Please submit a valid password token");
        return false;
      }
      return true;
    },
    async onSubmitPassToken() {
      if (!this.onSubmitPassTokenValidate(this.passToken)) return;
      // @TODO ini loading
      // confirm token
      try {
        await this.resetPassword({
          email: this.email,
          passToken: this.passToken,
        });
      } catch (e) {
        console.error(e);
        this.toastError("there was an error validating your code.");
        // @TODO complete loading
        return;
      }

      // @TODO complete loading
      this.passTokenSubmitted = true;
    },
    onSubmitPasswordValidate() {
      if (!this.passwordsMatch) {
        this.toastError("Your passwords do not match");
        return false;
      }
    },
    async onSubmitPassword(e) {
      e.preventDefault();
      if (!this.onSubmitPasswordValidate) return;
      // update password
      await this.resetPassword({
        email: this.email,
        passToken: this.passToken,
        plainPassword: this.plainPassword,
      });
      this.toastSuccess("Your password has been updated.");
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 2rem;
}
.brand {
  width: 34px;
  height: 34px;
  display: inline-block;
  background-color: #fff;
  background-image: url(../assets/img/logo-symbol.png);
  background-size: 30px;
  background-position: 0 4px;
  background-repeat: no-repeat;
}
input.sequential-code {
  height: 32px;
  width: 41px;
  font-size: 28px;
  text-align: center;
}
</style>
